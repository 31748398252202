import './Pin.scss'

import React from 'react'
import { useDispatch } from 'react-redux'

import { addStarToPin, removeStarFromPin } from '../../actions/pinsActions'
import common from '../../common'

export default function Pin(props) {
  const pin = props.pin
  const dispatch = useDispatch()

  const getPrettyTimeSpan = date => {
    const days = Math.round(
      Math.abs((new Date() - new Date(date)) / (24 * 60 * 60 * 1000))
    )

    if (days > 365) {
      return Math.round(days / 365) + 'y'
    }
    if (days > 30) {
      return Math.round(days / 30) + 'm'
    }
    if (days > 7) {
      return Math.round(days / 7) + 'w'
    }
    return days + 'd'
  }

  const starPressed = pin => {
    if (pin.StarGiven) {
      dispatch(removeStarFromPin(pin.ID))
    } else {
      dispatch(addStarToPin(pin.ID))
    }
  }

  return (
    <div className='pin-wrapper'>
      <div className='pin-top'>
        <img
          src={
            pin.LiteUser.ProfilePictureURL
              ? `${common.imagesBaseUrl}/${pin.LiteUser.ProfilePictureURL}`
              : '/images/default-user.png'
          }
          className='pin-profile-picture'
          alt='Profile Pic'
        />
        <div className='pin-top-middle-data'>
          <span className='name'>{pin.LiteUser.FullName.substr(0, 22)}</span>
          <br />
          <span className='stars-count'>{pin.ViewCount} views</span>
        </div>

        <div className='pin-top-right-data'>
          <span className='burger-options'>&nbsp;</span> <br />
          <span className='time-span'>{getPrettyTimeSpan(pin.Created)}</span>
        </div>
      </div>

      {pin.Images[0] && (
        <img
          className='pin-image'
          src={`${common.imagesBaseUrl}/${pin.Images[0].URL}`}
          alt={`Pin ${pin.ID}`}
        />
      )}

      {!pin.Images[0] && pin.Videos[0] && (
        <video width='100%' preload='auto' controls controlsList='nodownload'>
          <source
            src={`${common.videosBaseUrl}/${pin.Videos[0].URL}`}
            type='video/mp4'
          />
          <h4>Your browser does not support the video tag.</h4>
        </video>
      )}

      <div className='pin-bottom'>
        <div className='location-desc'>
          {pin.Location.LocationName && (
            <p className='location'>{pin.Location.LocationName}</p>
          )}
          {pin.Text && <p className='text'>{pin.Text}</p>}
        </div>
        <div className='star-wrapper'>
          <img
            src={pin.StarGiven ? 'images/star_on.svg' : 'images/star_off.svg'}
            alt='Star Icon'
            onClick={() => starPressed(pin)}
          />
        </div>
      </div>
    </div>
  )
}
