import './CustomSelect.scss'

import React, { useCallback, useEffect, useState } from 'react'

export default function CustomSelect(props) {
  let selectIndex = props.selectIndex
  let optionList = props.optionList
  let showOptions = props.showOptions
  let setShowOptions = props.setShowOptions
  let aboveSelectOpen = props.aboveSelectOpen
  let setSelectedData = props.setSelectedData
  let preselectedData = props.preselectedData
  let defaultSelectText = props.defaultText
  let multiple = props.multiple

  const [iconSrc, setIconSrc] = useState()
  const [displayValue, setDisplayValue] = useState(defaultSelectText)
  const [selectedOptions, setSelectedOptions] = useState(preselectedData || [])
  const [areAllSelected, setAreAllSelected] = useState(
    optionList?.length === preselectedData?.length
  )

  const generateDisplayValue = useCallback(
    names => {
      let maxLength = 30

      if (!names || names.length === 0) {
        return defaultSelectText
      }

      let newDisplayValue = names.join(', ')

      if (newDisplayValue.length > maxLength) {
        newDisplayValue = newDisplayValue.substring(0, maxLength) + '...'
      }

      return newDisplayValue
    },
    [defaultSelectText]
  )

  const handleOptionClick = e => {
    // TODO: refactor e.target...
    setDisplayValue(e.target.getAttribute('data-name'))

    setShowOptions(false, selectIndex)
    setSelectedData(e.target.getAttribute('data-id'), selectIndex)

    if (e.target.getAttribute('data-icon-src')) {
      setIconSrc(e.target.getAttribute('data-icon-src'))
    }
  }

  const handleMultipleOptionClick = e => {
    // TODO: refactor e.target...

    const clickedId = e.target.getAttribute('data-id')
    const clickedName = e.target.getAttribute('data-name')

    const index = selectedOptions.findIndex(
      x => x.Id === clickedId || x.ID === clickedId
    )

    let newSelectedOptions = []

    if (index === -1) {
      // select
      newSelectedOptions = [
        ...selectedOptions,
        { Id: clickedId, Name: clickedName },
      ]
      setSelectedOptions(newSelectedOptions)
      setDisplayValue(generateDisplayValue(newSelectedOptions.map(x => x.Name)))
      setSelectedData(
        newSelectedOptions.flatMap(x => [x.Id, x.ID].filter(Boolean))
      )
    } else {
      // deselect
      newSelectedOptions = selectedOptions.filter(
        x => x.Id !== clickedId && x.ID !== clickedId
      )
      setSelectedOptions(newSelectedOptions)
      setDisplayValue(generateDisplayValue(newSelectedOptions.map(x => x.Name)))
      setSelectedData(
        newSelectedOptions.flatMap(x => [x.Id, x.ID].filter(Boolean))
      )
    }

    if (newSelectedOptions.length === optionList.length) {
      setAreAllSelected(true)
    } else {
      setAreAllSelected(false)
    }
  }

  const handleSelectAllOptions = () => {
    let newSelectedOptions = []

    if (areAllSelected) {
      // deselect all
      setAreAllSelected(false)
    } else {
      // select all
      newSelectedOptions = optionList.map(x => ({
        Id: x.Id || x.ID,
        Name: x.Name,
      }))
      setAreAllSelected(true)
    }

    setSelectedOptions(newSelectedOptions)
    setDisplayValue(generateDisplayValue(newSelectedOptions.map(x => x.Name)))
    setSelectedData(
      newSelectedOptions.flatMap(x => [x.Id, x.ID].filter(Boolean))
    )
  }

  useEffect(() => {
    if (preselectedData) {
      if (multiple) {
        setDisplayValue(generateDisplayValue(preselectedData.map(x => x.Name)))
        setSelectedData(
          preselectedData.flatMap(x => [x.Id, x.ID].filter(Boolean))
        )
      } else {
        setDisplayValue(preselectedData.Name)
        setIconSrc(preselectedData.Icon)
        if (preselectedData.Id) {
          setSelectedData(preselectedData.Id)
        } else {
          setSelectedData(preselectedData.ID)
        }
      }
    } else {
      setDisplayValue(defaultSelectText)
      setIconSrc(null)
    }
  }, [
    generateDisplayValue,
    preselectedData,
    setSelectedData,
    defaultSelectText,
    multiple,
  ])

  return (
    <div className='custom-select-container' style={{ zIndex: props.zIndex }}>
      <div
        id={
          displayValue === defaultSelectText
            ? 'current-selected-option-default'
            : 'current-selected-option'
        }>
        {!aboveSelectOpen && iconSrc && (
          <img src={`images/${iconSrc}`} alt='option icon'></img>
        )}
        {!aboveSelectOpen && (
          <span id='current-value-select'>{displayValue}</span>
        )}
      </div>
      <div className='select-options-wrapper'>
        {/* Single select */}
        {showOptions && !multiple && (
          <ul className='rselect-options'>
            {optionList.map((option, index) => {
              return (
                <li
                  key={index}
                  className='rselect-option'
                  data-name={option.Name}
                  data-id={option.Id ? option.Id : option.ID}
                  data-icon-src={option.Icon}
                  onClick={handleOptionClick}>
                  {option.Icon && (
                    <img
                      src={`images/${option.Icon}`}
                      alt='option icon'
                      data-name={option.Name}
                      data-id={option.Id ? option.Id : option.ID}
                      data-icon-src={option.Icon}></img>
                  )}
                  <span
                    data-name={option.Name}
                    data-id={option.Id ? option.Id : option.ID}
                    data-icon-src={option.Icon}>
                    {option.Name}
                  </span>
                </li>
              )
            })}
          </ul>
        )}

        {/* Multiple select */}
        {showOptions && multiple && (
          <ul className='rselect-options'>
            <li className='rselect-option' onClick={handleSelectAllOptions}>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  checked={areAllSelected}
                  onChange={() => {}}
                />

                <label className={'disable-select multiple-select-label'}>
                  <span>Select All</span>
                </label>
              </div>
            </li>

            {optionList.map((option, index) => {
              return (
                <li
                  key={index}
                  className='rselect-option'
                  onClick={handleMultipleOptionClick}
                  data-name={option.Name}
                  data-id={option.Id ? option.Id : option.ID}>
                  <div className='checkbox'>
                    <input
                      type='checkbox'
                      checked={selectedOptions
                        .flatMap(item => [item.Id, item.ID].filter(Boolean))
                        .some(id => id === option.Id || id === option.ID)}
                      onChange={() => {}}
                    />

                    <label
                      data-name={option.Name}
                      data-id={option.Id ? option.Id : option.ID}
                      className={'disable-select multiple-select-label'}>
                      <span
                        data-name={option.Name}
                        data-id={option.Id ? option.Id : option.ID}>
                        {option.Name}
                      </span>
                    </label>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}
